<template>
  <b-card v-if="statisticsItems" no-body class="card-statistics">
    <b-card-header>
      <b-card-title>Refurbish Revenue</b-card-title>
      <!-- <b-card-text class="font-small-2 mr-25 mb-0">
        Updated 1 month ago
      </b-card-text> -->
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <b-col
          v-for="(item, index) in statisticsItems"
          :key="index + 'item'"
          xl="6"
          sm="12"
          :class="item.customClass"
        >
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" :variant="item.color">
                <feather-icon size="24" :icon="item.icon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ item.title }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ item.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  // props: {
  //   data: {
  //     type: Array,
  //     default: () => [],
  //   },
  // },
  data() {
    return {
      statisticsItems: [
        {
          icon: "TrendingUpIcon",
          color: "light-primary",
          title: "1",
          subtitle: "Sales",
          customClass: "mb-3",
        },
        {
          icon: "PercentIcon",
          color: "light-info",
          title: " £",
          subtitle: "Credit",
          customClass: "mb-3",
        },
        {
          icon: "BoxIcon",
          color: "light-danger",
          title: " £",
          subtitle: "Total Earned",
          customClass: "mb-2 mt-2",
        },
        {
          icon: "LoaderIcon",
          color: "light-success",
          title: " £",
          subtitle: "Pending Amount",
          customClass: "mt-2",
        },
      ],
    };
  },
};
</script>
