<template>
  <b-card v-if="data" no-body class="card-revenue-budget d-none">
    <!-- <b-row class="mx-0"> -->
    <b-col md="12" class="revenue-report-wrapper d-none">
      <div class="d-sm-flex justify-content-between align-items-center mb-3">
        <h4 class="card-title mb-50 mb-sm-0 text-black">
          ITEMS &amp; SALES REPORT
        </h4>
        <div class="d-flex align-items-center">
          <div class="d-flex align-items-center mr-2">
            <span
              class="
                bullet bullet-primary
                svg-font-small-3
                mr-50
                cursor-pointer
              "
            />
            <span>Earning</span>
          </div>
        </div>
      </div>
      <!-- {{ data.revenueReport.series }} -->
      <!-- chart -->
      <vue-apex-charts
        id="revenue-report-chart"
        type="bar"
        height="230"
        :options="chartOptions"
        :series="earningsData"
      />
    </b-col>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BButton,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import Ripple from "vue-ripple-directive";
import DashboardServices from "@/apiServices/DashboardServices";

export default {
  components: {
    VueApexCharts,
    BDropdown,
    BDropdownItem,
    BCard,
    BButton,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      earningsData: [
        {
          name: "Earnings",
          data: Array(12).fill(0),
        },
      ],
      chartOptions: {
        chart: {
          stacked: true,
          type: "bar",
          toolbar: { show: false },
        },
        grid: {
          padding: {
            top: -20,
            bottom: -10,
          },
          yaxis: {
            lines: { show: false },
          },
        },
        yaxis: {
          show: true,
          labels: {
            show: true,
          },
          title: {
            text: "Amount in GBP",
          },
        },
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
          labels: {
            style: {
              colors: "#6E6B7B",
              fontSize: "0.86rem",
              fontFamily: "Montserrat",
            },
          },
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        colors: [$themeColors.primary, $themeColors.warning],
        plotOptions: {
          bar: {
            columnWidth: "17%",
            endingShape: "rounded",
          },
          distributed: true,
        },
        yaxis: {
          labels: {
            style: {
              colors: "#6E6B7B",
              fontSize: "0.86rem",
              fontFamily: "Montserrat",
            },
          },
        },
      },
      // budget chart
      budgetChart: {
        options: {
          chart: {
            height: 80,
            toolbar: { show: false },
            zoom: { enabled: false },
            type: "line",
            sparkline: { enabled: true },
          },
          stroke: {
            curve: "smooth",
            dashArray: [0, 5],
            width: [2],
          },
          colors: [$themeColors.primary, "#dcdae3"],
          tooltip: {
            enabled: false,
          },
        },
      },
    };
  },
  methods: {
    async getEarningSalesReport() {
      try {
        const response = await DashboardServices.getItemSalesStats();
        if (response.data.status) {
          let chartOptions = JSON.parse(JSON.stringify(this.chartOptions));
          chartOptions.xaxis.categories = response.data.data.months;
          this.chartOptions = chartOptions;
          this.earningsData[0].data = response.data.data.earnings;
        }
      } catch (err) {
        console.log("Error in getting  ", err);
      }
    },
  },
  beforeMount() {
    this.getEarningSalesReport();
  },
};
</script>
