<template>
  <b-card v-if="statisticsItems" no-body class="card-statistics">
    <b-card-header>
      <b-card-title class="text-black"> SALES &amp; HISTORY </b-card-title>
      <!-- <b-card-text class="font-small-2 mr-25 mb-0">
        Updated 1 month ago
      </b-card-text> -->
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <b-col
          v-for="(item, index) in statisticsItems"
          :key="index + 'statItem'"
          xl="3"
          sm="12"
          :class="item.customClass"
        >
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" :variant="item.color">
                <!-- <feather-icon size="24" :icon="item.icon" /> -->
                <font-awesome-icon style="font-size: 20px" :icon="item.icon" />
                <!-- {{ item.icon }} -->
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0 text-black">
                {{ item.title }}
              </h4>
              <b-card-text class="font-small-3 mb-0 text-black">
                {{ item.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
} from "bootstrap-vue";
import DashboardServices from "@/apiServices/DashboardServices";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  // props: {
  //   data: {
  //     type: Array,
  //     default: () => [],
  //   },
  // }, Option should be Live Items, Earnings, Number of Sales

  data() {
    return {
      statisticsItems: [
        {
          icon: "fa-solid fa-sack-dollar",
          color: "light-primary",
          title: "1",
          subtitle: "Number of Sales",
          customClass: "mb-2",
        },
        {
          icon: "fa-solid fa-sterling-sign",
          color: "light-primary",
          title: " £",
          subtitle: "Credit",
          customClass: "mb-2",
        },
        {
          icon: "fa-solid fa-chart-pie",
          color: "light-primary",
          title: " £",
          subtitle: "Total Earned",
          customClass: "mb-2",
        },
      ],
    };
  },
  methods: {
    async getItemsSold() {
      try {
        const response = await DashboardServices.getItemsSold();
        console.log(response.data);
        if (response.data.status) {
          this.statisticsItems[0].title = response.data.data.prod_sold_count;
          this.statisticsItems[1].title =
            "£" + response.data.data.prod_sold_amount;
          this.statisticsItems[2].title = "£" + response.data.data.earnings;
        }
      } catch (err) {
        console.log("Error in getting  ", err);
      }
    },
  },
  beforeMount() {
    this.getItemsSold();
  },
};
</script>

<style>
.text-black {
  color: black;
}
</style>