<template>
  <b-card v-if="allItemsData" no-body class="card-statistics">
    <b-card-header>
      <b-card-title class="text-black"> ITEMS </b-card-title>
    </b-card-header>

    <!--  SELLER VARD BODY -->
    <b-card-body class="statistics-body" v-if="user_role == 'SELLER'">
      <b-row>
        <b-col
          v-for="(item, index) in statisticsItemsSeller"
          :key="index + 'seller_item'"
          xl="3"
          sm="6"
          :class="item.customClass"
        >
          <b-media
            no-body
            :to="{
              name: `status-table`,
              query: { id: item.statusCode },
            }"
            class="font-weight-bold"
            @click="
              $router.push({
                name: `status-table`,
                query: { id: item.statusCode },
              })
            "
          >
            <b-media-aside class="mr-2">
              <b-avatar size="48" :variant="item.color">
                <feather-icon size="24" :icon="item.icon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ allItemsData.sellerAllData[item.statusSellerKey] }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ item.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>

    <!-- ADMIN CARD BODY -->
    <b-card-body class="statistics-body" v-if="user_role == 'ADMIN'">
      <b-row>
        <b-col
          v-for="(item, index) in statisticsItemsAdmin"
          :key="index + 'adminItems'"
          xl="3"
          sm="6"
          :class="item.customClass"
        >
          <b-media
            no-body
            :to="{
              name: `status-table`,
              query: { id: item.statusCode },
            }"
            class="font-weight-bold"
            @click="
              $router.push({
                name: `status-table`,
                query: { id: item.statusCode },
              })
            "
          >
            <b-media-aside class="mr-2">
              <b-avatar size="48" :variant="item.color">
                <feather-icon size="24" :icon="item.icon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ allItemsData[item.statusAdminKey] }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ item.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
} from "bootstrap-vue";

import storageService from "@/apiServices/storageService";
import DashboardServices from "@/apiServices/DashboardServices";
import StripeVendorServices from "@/apiServices/StripeVendorServices";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  data() {
    return {
      statisticsItemsSeller: [
        {
          icon: "SaveIcon",
          color: "light-primary",
          title: "2",
          subtitle: "Saved Items",
          customClass: "mb-2 mb-xl-0 pb-1 pointer",
          statusCode: "1",
          statusSellerKey: "SELLER_ALL_SAVED_ITEMS", 
          // SELLER_ALL_SAVED_ITEMS - SELLER_ALL_DRAFT_ITEMS
        },
        {
          icon: "ClipboardIcon",
          color: "light-primary",
          title: "2",
          subtitle: "Under Review",
          customClass: "mb-2 mb-xl-0 pb-1 pointer",
          statusCode: "1",
          statusSellerKey: "SELLER_ALL_S1_UNDER_REVIEW",
        },
        {
          icon: "XCircleIcon",
          color: "light-primary",
          title: "2",
          subtitle: "Rejected",
          customClass: "mb-2 mb-xl-0",
          statusCode: "1",
          statusSellerKey: "SELLER_ALL_S4_ITEM_REJECTED",
        },
        {
          icon: "CheckCircleIcon",
          color: "light-primary",
          title: "2",
          subtitle: "Approved",
          customClass: "mb-2 mb-xl-0",
          statusCode: "1",
          statusSellerKey: "SELLER_ALL_S5_APPROVED",
        },
        {
          icon: "TruckIcon",
          color: "light-primary",
          title: "2",
          subtitle: "Shipped",
          customClass: "mb-2 mb-xl-0",
          statusCode: "1",
          statusSellerKey: "SELLER_ALL_S6_SHIPPED",
        },
        {
          icon: "BoxIcon",
          color: "light-primary",
          title: "2",
          subtitle: "Received",
          customClass: "mb-2 mb-xl-0 pb-1",
          statusCode: "1",
          statusSellerKey: "SELLER_ALL_S7_ITEM_RECEIVED",
        },
        {
          icon: "PenToolIcon",
          color: "light-primary",
          title: "2",
          subtitle: "Under Appriasal",
          customClass: "mb-2 mb-xl-0",
          statusCode: "1",
          statusSellerKey: "SELLER_ALL_S8_UNDER_APPRIASAL",
        },
        {
          icon: "XOctagonIcon",
          color: "light-primary",
          title: "2",
          subtitle: "Appriasal Rejected",
          customClass: "mb-2 mb-xl-0",
          statusCode: "1",
          statusSellerKey: "SELLER_ALL_S9_UN_REJECTED",
        },
        {
          icon: "SendIcon",
          color: "light-primary",
          title: "2",
          subtitle: "Quotation Sent",
          customClass: "mb-2 mb-xl-0",
          statusCode: "1",
          statusSellerKey: "SELLER_ALL_S10_QUOTED",
        },
        {
          icon: "XIcon",
          color: "light-primary",
          title: "2",
          subtitle: "Quotation Rejected",
          customClass: "mb-2 mb-xl-0",
          statusCode: "1",
          statusSellerKey: "SELLER_ALL_S11_QUOTATION_REJECTED",
        },
        {
          icon: "CheckIcon",
          color: "light-primary",
          title: "2",
          subtitle: "Quotation Accepted",
          customClass: "mb-2 mb-xl-0",
          statusCode: "1",
          statusSellerKey: "SELLER_ALL_S12_QUOTATION_ACCEPTED",
        },
        {
          icon: "TagIcon",
          color: "light-primary",
          title: "2",
          subtitle: "Live Items",
          customClass: "mb-2 mb-xl-0",
          statusCode: "1",
          statusSellerKey: "SELLER_ALL_S13_ITEM_LIVE",
        },
        {
          icon: "PackageIcon",
          color: "light-primary",
          title: "2",
          subtitle: "Sold Items",
          customClass: "mb-2 mb-xl-0 mt-1",
          statusCode: "1",
          statusSellerKey: "SELLER_ALL_S13_ITEM_LIVE",
        },
        {
          icon: "BookIcon",
          color: "light-primary",
          title: "2",
          subtitle: "Payment Pending",
          customClass: "mb-2 mb-xl-0 mt-1",
          statusCode: "1",
          statusSellerKey: "SELLER_ALL_S13_ITEM_LIVE",
        },
        {
          icon: "CheckSquareIcon",
          color: "light-primary",
          title: "2",
          subtitle: "Payment Approved",
          customClass: "mb-2 mb-xl-0 mt-1",
          statusCode: "1",
          statusSellerKey: "SELLER_ALL_S13_ITEM_LIVE",
        },
      ],

      statisticsItemsAdmin: [
        {
          icon: "SaveIcon",
          color: "light-primary",
          title: "2",
          subtitle: "Saved Items",
          customClass: "mb-2 mb-xl-0 pb-1 pointer",
          statusCode: "1",
          statusAdminKey: "S1_UNDER_REVIEW",
        },
        {
          icon: "MonitorIcon",
          color: "light-primary",
          title: "2",
          subtitle: "Under Review",
          customClass: "mb-2 mb-xl-0 pb-1 pointer",
          statusCode: "1",
          statusAdminKey: "S1_UNDER_REVIEW",
        },
        {
          icon: "XSquareIcon",
          color: "light-primary",
          title: "2",
          subtitle: "Rejected",
          customClass: "mb-2 mb-xl-0",
          statusCode: "1",
          statusAdminKey: "S4_ITEM_REJECTED",
        },
        {
          icon: "CheckCircleIcon",
          color: "light-primary",
          title: "2",
          subtitle: "Aproved",
          customClass: "mb-2 mb-xl-0",
          statusCode: "1",
          statusAdminKey: "S5_APPROVED",
        },
        {
          icon: "ShoppingCartIcon",
          color: "light-primary",
          title: "2",
          subtitle: "Shipped",
          customClass: "mb-2 mb-xl-0",
          statusCode: "1",
          statusAdminKey: "S6_SHIPPED",
        },
        {
          icon: "UmbrellaIcon",
          color: "light-primary",
          title: "2",
          subtitle: "Received",
          customClass: "mb-2 mb-xl-0 pb-1",
          statusCode: "1",
          statusAdminKey: "S7_ITEM_RECEIVED",
        },
        {
          icon: "RefreshCcwIcon",
          color: "light-primary",
          title: "2",
          subtitle: "Under Appriasal",
          customClass: "mb-2 mb-xl-0",
          statusCode: "1",
          statusAdminKey: "S8_UNDER_APPRIASAL",
        },
        {
          icon: "XOctagonIcon",
          color: "light-primary",
          title: "2",
          subtitle: "Appriasal Rejected",
          customClass: "mb-2 mb-xl-0",
          statusCode: "1",
          statusAdminKey: "S9_UN_REJECTED",
        },
        {
          icon: "MonitorIcon",
          color: "light-primary",
          title: "2",
          subtitle: "Quotation Sent",
          customClass: "mb-2 mb-xl-0",
          statusCode: "1",
          statusAdminKey: "S10_QUOTED",
        },
        {
          icon: "XSquareIcon",
          color: "light-primary",
          title: "2",
          subtitle: "Quotation Rejected",
          customClass: "mb-2 mb-xl-0",
          statusCode: "1",
          statusAdminKey: "S11_QUOTATION_REJECTED",
        },
        {
          icon: "BoxIcon",
          color: "light-primary",
          title: "2",
          subtitle: "Quotation Accepted",
          customClass: "mb-2 mb-xl-0",
          statusCode: "1",
          statusAdminKey: "S12_QUOTATION_ACCEPTED",
        },

        {
          icon: "ShoppingBagIcon",
          color: "light-primary",
          title: "2",
          subtitle: "Live Items",
          customClass: "mb-2 mb-xl-0",
          statusCode: "1",
          statusAdminKey: "S13_ITEM_LIVE",
        },

        {
          icon: "ShoppingBagIcon",
          color: "light-primary",
          title: "2",
          subtitle: "Sold Items",
          customClass: "mb-2 mb-xl-0 mt-2 ",
          statusCode: "1",
          statusAdminKey: "",
        },
        // {
        //   icon: "ShoppingBagIcon",
        //   color: "light-primary",
        //   title: "2",
        //   subtitle: "Payment Pending",
        //   customClass: "mb-2 mb-xl-0 mt-2",
        //   statusCode: "1",
        //   statusAdminKey: "",
        // },
        // {
        //   icon: "ShoppingBagIcon",
        //   color: "light-primary",
        //   title: "2",
        //   subtitle: "Payment Approved",
        //   customClass: "mb-2 mb-xl-0 mt-2",
        //   statusCode: "1",
        //   statusAdminKey: "",
        // },
      ],

      allItemsData: [],

      user_role: "",

      // stripe_json: {}
    };
  },
  beforeMount() {
    this.loadStats();
  },
  methods: {
    loadStats() {
      const profileData = storageService.UserData.getUserProfile();
      let userInfo = JSON.parse(profileData);
      let user_id = userInfo.user_id;
      let user_role_id = parseInt(userInfo.user_role_id);

      if (user_role_id == 1) {
        this.user_role = "SELLER";

        //seller/1/ADMIN/16

        DashboardServices.getUserDashboard("sell", user_id, "SELLER", 1)
          .then((response) => {
            this.allItemsData = response.data.data;
            console.log("allItemsData", this.allItemsData);
          })
          .catch((error) => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
          });

      }

      if (user_role_id == 2) {
        this.user_role = "ADMIN";

        DashboardServices.getAdmin()
          .then((response) => {
            this.allItemsData = response.data.data;
          })
          .catch((error) => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
          });
      }
    },

    // loadStripeAccountLink(user_id){
    //   console.log("loadStripeAccountLink");
    //   StripeVendorServices.getAccountLink(user_id)
    //     .then((response) => {
    //       this.stripe_json = response.data.data;
    //       console.log("stripe_json: ", JSON.parse(this.stripe_json.strpven_json_data));
    //     })
    //     .catch((error) => {
    //       this.errorMessage = error.message;
    //       console.error("There was an error! in notifications", error);
    //     });
    // }
  },
};
</script>

<style>
.pointer {
  cursor: pointer;
}
</style>
